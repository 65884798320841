body {
  font-family: 'Lexend';
}



@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-gradient-animated {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
@keyframes gradientChange {
  0% {
          background-position: 0%
  }

  50% {
          background-position: 100%
  }

  100% {
          background-position: 0%
  }
}

.gradient {
  background: linear-gradient(90deg, blue, red);
  background-size: 200% 200%;
}

.element-to-rotate {
  animation: gradientChange 4s ease infinite;
}

:root {
  --tw-color-1: #ff5858;
  --tw-color-2: #ff655b;
  --tw-color-3: #ff7f00;
  --tw-color-4: #ff00ff;
  --tw-color-5: #6550ff;
  --tw-color-6: #50ff7f;
  --tw-color-7: #ffab00;
  --tw-color-8: #00abff;
}

.carousel {
  --crsl-bg: transparent;
  --box-bg: #1e272e;
  --box-shadow: #0000001c;
  --box-shadow-border: #0000000f;
  --box-border: #fff;
}
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(47,53,57,1) 0%, rgba(30,39,46,1) 100%);
  font-family: 'Source Sans Pro', sans-serif;
}

.item__3d-frame {
  perspective: 1200px;
}

.item__3d-frame__box--left {
  top: 0;
  left: 0;
  width: 78px;
  height: 100%;
  transform-origin: 0%;
  backface-visibility: hidden;
  border-left-width: 5px;
  transform: translate3d(1px, 0, -40px) rotateY(-90deg);
}

.item__3d-frame__box--right {
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  transform-origin: 100%;
  backface-visibility: hidden;
  border-right-width: 5px;
  transform: translate3d(-1px, 0, -40px) rotateY(90deg);
}
.hover-border::after {
  display: block;
  content: '';
  width: inherit;
  border-bottom: solid 2px rgb(158, 158, 158);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.hover-border:hover::after {
  transform: scaleX(1);
}
/* Sliding effect for mobile menu */
.slider-enter {
  transform: translateX(100%);
}
.slider-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.slider-exit {
  transform: translateX(0);
}
.slider-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.gradient-thumb {
  background: linear-gradient(to right, #ff7e5f, #feb47b); /* Example gradient */
  border-radius: 50%;
}
@keyframes gradientChange {
  0% {
          background-position: 0%
  }

  50% {
          background-position: 100%
  }

  100% {/* Aboutimage  */

          background-position: 0%
  }
}

.gradient {
  background: linear-gradient(90deg, blue, red);
  background-size: 200% 200%;
}

.element-to-rotate {
  animation: gradientChange 4s ease infinite;
}

@layer utilities {
@keyframes gradientChange {
  0% {
          background-position: 0%
  }

  50% {
          background-position: 100%
  }

  100% {
          background-position: 0%
  }
}



.element-to-rotate {
  animation: gradientChange 4s ease infinite;
}
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 5;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.animate-animate {
  animation: animate 3s infinite;
}
.slick-dots li button:before {
  color: white; /* Change the dot color to white */
}

.slick-dots li.slick-active button:before {
  color: white; /* Change the active dot color to white */
}
.custom-bounce {
  @apply animate-bounce;
  animation-duration: 50ms; 
  animation-timing-function: ease-in-out; 
}



@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
.rotate-gradient {
  animation: spin 4s linear infinite;
  animation-timing-function: linear; /* Ensure constant speed */

  
} @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        .rotate-gradient {
            animation: spin 4s linear infinite;
            animation-timing-function: linear; /* Ensure constant speed */
        }

        .bungee-shade-regular {
          font-family: "Bungee Shade", sans-serif;
          font-weight: 400;
          font-style: normal;
        }
        .text-font {
          font-family: New Kansas Regular5695;
      }
      